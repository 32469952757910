export const SUPPORTED_REGIONS = ["eu-west-1", "ca-central-1"];
export const BACKEND_REGIONS = [...SUPPORTED_REGIONS, "us-east-1"];
export const HSO_REGIONS = {
  "ca-central-1": "CA",
  "eu-west-1": "UK",
};
export const AWS_DEV_ACCOUNT_ID = "631303892699";
export const AWS_PROD_ACCOUNT_ID = "770966829753";
export const ANY_PRACTITIONER_ID = "ANY_PRACTITIONER_ID";
export const PREVIEW_SITE_APPOINTMENT_MANAGE_TO_PATIENT = "Manage app > Site appointment preview component";
export const PREVIEW_SITE_APPOINTMENT_PATIENT_TO_MANAGE = "Site appointment preview component > Manage app";
export const ERROR_CODES = {
  DATE_OF_BIRTH_MISMATCH: "DATE_OF_BIRTH_MISMATCH",
};
export const FORMATS = {
  get LOCALE_DATE() {
    return "DD/MM/YYYY";
  },
};
export const BACKSTOP_DEFAULT_DOMAIN_ID = "f4a3d8be-2e88-48ad-b31f-60744889dff4";
export const APPOINTMENT_BOOKING = {
  EXISTING_PATIENT_TIMEOUT_IN_SECONDS: 5 * 60, // 5 minutes
  NEW_PATIENT_TIMEOUT_IN_SECONDS: 10 * 60, // 10 minutes
};
export const RESERVED_SUBDOMAINS = ["api", "beta", "apis", "booking", "developer", "pay", "preferences", "pair", "sign"]; //also update the list in patient-app/lib/functions/default-behaviour/viewer-respoonse/index.js
export const GLOBAL_DYNAMODB_TABLES = {
  DOMAIN_TO_STAGE_MAP: "global-domain-to-stage-map",
};

export enum E_PipServiceMode {
  KIOSK = "KIOSK",
  CONCIERGE = "CONCIERGE",
}

export enum E_PipDeviceStatus {
  PAIRED = "PAIRED",
  LOCKED = "LOCKED",
}

export enum E_FormsSettingName {
  NHS_PR = "forms__nhs_pr",
  MEDICAL_HISTORY = "forms__medical_history",
  TREATMENT_PLAN_ESTIMATES = "forms__treamtent_plan_estimates",
}

export enum E_PaymentsSettingName {
  BALANCE_PAYMENTS = "payments__balance_payments",
}

export enum E_PatientNotificationsSettingName {
  BOOKING_SUCCESS_SMS = "comms__booking_confirmation__patient_sms",
  BOOKING_CANCELLATION_SMS = "comms__booking_cancellation__patient_sms",
  TASK_REMINDERS_SMS = "comms__task_reminders__patient_sms",
  BOOKING_SUCCESS_EMAIL = "comms__booking_confirmation__patient_email",
  BOOKING_CANCELLATION_EMAIL = "comms__booking_cancellation__patient_email",
  TASK_REMINDERS_EMAIL = "comms__task_reminders__patient_email",
}

export enum E_PatientNotificationsType {
  BOOKING_SUCCESS = "BOOKING_SUCCESS",
  BOOKING_CANCELLATION = "BOOKING_CANCELLATION",
  TASK_REMINDERS = "TASK_REMINDERS",
}

export enum E_CategoryIds {
  Consultation = "90505a43-2284-4a2f-a2c6-21e0011d6ce6",
  Emergency = "cfea738e-b26a-4f6d-9e22-5be577ffeb45",
  ExistingDental = "e763d4b7-2591-4695-8ecc-3e4bb913c261",
  ExistingHygiene = "b0b3f514-67ff-4d92-b6c4-3b05a03c49dc",
  NewLapsedDental = "f7656bb5-d151-44c3-8c7b-7fa3ed56d817",
  NewLapsedHygiene = "00ca37f0-f426-46c5-add3-4aedd65b32ee",
}

export enum E_GlobalDomains {
  SANDBOX = "sandbox.portal.dental",
  PRODUCTION = "portal.dental",
}

export namespace CustomDomains {
  export enum Bupa {
    England = "dental.bupa.co.uk",
    Scotland = "dental-scotland.bupa.co.uk",
    Wales = "dental-wales.bupa.co.uk",
    RepublicOfIreland = "dental.smiles.ie",
    NorthernIreland = "dental-ni.bupa.co.uk",
  }
}

export namespace ManageDomains {
  export enum UK {
    SANDBOX = "portal.sandbox.dentally.com",
    PRODUCTION = "portal.dentally.com",
  }

  export enum CA {
    SANDBOX = "portal.sandbox.ca.dentally.com",
    PRODUCTION = "portal.ca.dentally.com",
  }
}

export const RegionToManageDomains = {
  "eu-west-1": ManageDomains.UK,
  "ca-central-1": ManageDomains.CA,
};

export const ALLOWED_TITLES = ["Mr", "Mrs", "Miss", "Ms", "Master", "Mx", "Dr", "Prof", "Rev", "Sir", "Lady", "Dame", "Hon", "Lord", "Earl", "Judge"];

export const TRACE_ID_HEADER = "x-trace-id";
export const TRACE_ID_PROPERTY_NAME = "trace.id";
export const UI_VERSION_HEADER = "x-ui-version";
export const UI_VERSION_PROPERTY_NAME = "version.ui";
export const BACKEND_VERSION_PROPERTY_NAME = "version.backend";

export namespace USER_SESSION {
  export const PATIENT: Record<"SESSION_EXPIRY" | "KEEP_ALIVE_INTERVAL", number> = {
    get SESSION_EXPIRY(): number {
      return 2 * 60 * 60 * 1000; // 2 hours
    },
    get KEEP_ALIVE_INTERVAL(): number {
      return 5 * 60 * 1000; // 5 minutes
    },
  };
}
