import { I_MissingPatientDetails } from "../patient-details/patient-details-base";
import { Action, E_ActionStatus } from "../patients/patient-base";
import { ActionMetadata, E_ActionType } from "../patients/patient-base";
import { T_ValidAppointment } from "./routine-appointment-base";

export function isPendingAction(action: Action): boolean {
  return action.required && action.status === E_ActionStatus.Pending;
}

export interface I_EstimateData {
  id: number;
  template_name: string;
  completed?: boolean;
}

export interface NewPatientAction {
  pms_action?: Action;
  type: E_Patient_Actions_Type;
  text: string;
  appointment_ids?: Array<string>;
  appointment_id?: string;
  required?: boolean;
}

export interface NewDerivedAction {
  appointment_id?: string;
  text: string;
  appointment_ids?: Array<string>;
  type: E_Patient_Actions_Type;
}

export enum E_Restricted_Patient_Actions_Type {
  MEDICAL_HISTORY = "MEDICAL_HISTORY",
  PAYMENT = "PAYMENT",
  CHECK_IN = "CHECK_IN",
  ROUTINE_DENTAL_EXAM = "ROUTINE_DENTAL_EXAM",
  ROUTINE_HYGIENE_APPT = "ROUTINE_HYGIENE_APPT",
  ROUTINE_APPT = "ROUTINE_APPT",
}

export enum E_Patient_Actions_Type {
  CHECK_IN = "CHECK_IN",
  MEDICAL_HISTORY = "MEDICAL_HISTORY",
  NHS_PR = "NHS_PR",
  PAYMENT = "PAYMENT",
  TREATMENT_PLAN_ESTIMATE = "TREATMENT_PLAN_ESTIMATE",
  ROUTINE_DENTAL_EXAM = "ROUTINE_DENTAL_EXAM",
  ROUTINE_HYGIENE_APPT = "ROUTINE_HYGIENE_APPT",
  PATIENT_DETAILS = "PATIENT_DETAILS",
  ROUTINE_APPT = "ROUTINE_APPT", // not a real action returned from the BE, but used by Dentally in action links
  SMILE_GOAL = "SMILE_GOAL",
}

export class PatientActionBase {
  public practice_id: string;
  public patient_id: string;

  /**
   * @deprecated use appointment_ids instead
   */
  public appointment_id = "";
  public appointment_ids = new Array<string>(); // Will replace appointment_id once we move over to derive all actions from Dentally

  /**
   * @property type - A generic action type which can be used for multiple actions (e.g. FP17 and GP17 are both NHS_PR)
   * @property pms_action_type - The specific action type (e.g. FP17 or GP17_Part1 or GP17_Part2)
   */
  public type: E_Patient_Actions_Type;
  public pms_action_type: E_ActionType;

  public text: string;
  public data: PatientActionData = {};
  public task_reminder_sent_at: Date | null = null;
  public required: boolean;

  constructor(practice_id: string, patient_id: string, data: NewPatientAction) {
    const { appointment_id, pms_action, text, appointment_ids, type, required } = data;
    this.practice_id = practice_id;
    this.patient_id = patient_id;
    this.type = type;
    this.text = text;
    this.required = required ?? true;

    if (pms_action) {
      this.pms_action_type = pms_action.action_type;
      this.required = isPendingAction(pms_action);
    }

    if (appointment_id) this.appointment_id = appointment_id;
    if (appointment_ids) this.appointment_ids = appointment_ids;
  }
}

export type PatientActionMetadata = ActionMetadata & CustomActionMetaData;

// This is a custom metadata that is used in the BE to store additional data for the action on top of the PMS metadata
export type CustomActionMetaData = {
  patient_exemption_code?: string;
  form_type?: E_ActionType;
};

export class PatientActionData {
  public question_count?: number;
  public routine_appt_data?: Array<T_ValidAppointment>;
  public estimates?: Array<I_EstimateData>;
  public patient_details_completed_percentage?: number;
  public patient_details_missing_data?: I_MissingPatientDetails;
  public meta_data?: PatientActionMetadata;
}
