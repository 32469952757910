import { HttpClient } from "@angular/common/http";
import { ClassProvider, Injectable } from "@angular/core";
import Bugsnag from "@bugsnag/js";
import { navigateToUnsupported } from "../utils/unsupported";
import { BrandService } from "./brand.service";
import { JWTService } from "./jwt.service";
import { PatientsService } from "./patients.service";
import { BookableApptsService } from "./bookable-appts.service";
import { LocationService } from "./location.service";
import { lastValueFrom } from "rxjs";
import { CacheService } from "./cache.service";
import { environment } from "src/environments/environment";
import { NavigationService } from "./navigation.service";
import { PortalInPracticeService } from "./portal-in-practice.service";
import { CommonService } from "./common.service";
import * as utils from "src/app/utils";
import { SessionService } from "./session.service";
import { RumService } from "./rum.service";
import { EventsService } from "./events.service";
import { NewRelicService } from "./new-relic.service";
import { notifyAndLeaveBreadcrumb, setupBugsnag } from "../utils/logging";
import { Constants } from "src/constants";

const REGION_STORAGE_KEY = "region";
const STAGE_STORAGE_KEY = "stage";
const HOSTNAME_STORAGE_KEY = "hostname";
const PREVIOUS_SHORT_CODE_STORAGE_KEY = "previous_short_code";

@Injectable()
export class AppInitService {
  constructor(
    protected _commonService: CommonService,
    protected _brandService: BrandService,
    protected _patientService: PatientsService,
    protected _jwtService: JWTService,
    protected _bookableApptsService: BookableApptsService,
    protected _locationService: LocationService,
    protected _cacheService: CacheService,
    protected _http: HttpClient,
    protected _navigationService: NavigationService,
    protected _portalInPracticeService: PortalInPracticeService,
    protected _sessionService: SessionService,
    protected _rumService: RumService,
    protected _eventService: EventsService,
    protected _newRelicService: NewRelicService
  ) {}

  private async _handleRootDomain(): Promise<boolean> {
    const queryParams = this._locationService.search;
    const match = this._locationService.pathname.match(/^\/p\/([^\/]+)\/([^\/]+)$/);
    const practiceId = match ? match[1] : queryParams.practice_id;
    const patientId = match ? match[2] : queryParams.patient_id;

    if (!patientId || !practiceId) return false;

    const response = (await lastValueFrom(
      this._http.get(`${environment.GLOBAL_URL}/v1/patients/${patientId}`, {
        headers: {
          practice: practiceId,
        },
      })
    )) as { domain: string; region: string; stage: string; token: string };

    this._locationService.actualHostname = response.domain;
    this._locationService.hostname = response.domain;
    window.REGION = response.region;
    window.STAGE = response.stage;
    this._jwtService.setToken(response.token);
    this._cacheService.setSession(REGION_STORAGE_KEY, response.domain);
    this._cacheService.setSession(STAGE_STORAGE_KEY, response.stage);

    return true;
  }

  private async _handlePreferencesDomain(): Promise<boolean> {
    if (!this._locationService.isPreferencesDomain) return false;

    const path = "preferences/unsubscribe";
    const { pathname } = this._locationService;

    setupBugsnag();

    if (pathname === `/${path}`) return true;

    const queryParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

    await this._determinePracticeStage(queryParams.practice_id);

    this._navigationService.navigate("preferences/unsubscribe", {
      state: queryParams,
      replaceUrl: true,
    });

    return true;
  }

  public async init() {
    this._handleCookies(document.cookie);
    this._rumService.setup();

    // Initialize the session service first to ensure we have the session cookie set
    await this._sessionService.init();

    try {
      await this._doInit();
    } catch (error) {
      notifyAndLeaveBreadcrumb("Failed to initialize app", {
        error,
        pipData: this._cacheService.getJson(Constants.PIP_DATA_STORAGE_KEY),
        jwt: this._jwtService.getJWT(),
      });

      this._navigationService.navigate("/error", {
        state: {
          error: "Failed to initialise the application",
        },
      });
    }
  }

  // eslint-disable-next-line complexity
  protected async _doInit() {
    if (!this._locationService.isRootDomain || !(await this._handleRootDomain())) {
      if (await this._handlePreferencesDomain()) return;

      // The domain must include a brand subdomain so we need to determine the stage. This needs to be done before starting Bugsnag
      await this._determineDomainStage();
    }

    setupBugsnag();

    if (!window.localStorage || !window.sessionStorage) {
      navigateToUnsupported();
      return;
    }

    try {
      await this._handleGlobalDomains();
    } catch {
      this._redirectToLinkExpired();
      return;
    }

    try {
      // Wait for domain info to be fetched before continuing so we correctly set info such as the country code/brand info avoiding any race conditions
      await this._commonService.getDomainInfo();

      if (this._jwtService.isPatient()) {
        await this._patientService.getPatient();
      }
    } catch (error) {
      console.error("Failed to get domain info or patient", error);

      utils.hideAppLoadingSpinner();

      return;
    }

    // If we're not logged in as a patient we need to check the url for it's requested alias here
    if (!this._jwtService.isPatient()) {
      const { pathname } = this._locationService;
      if (pathname?.startsWith("/practices/")) {
        let alias = pathname.substring(11);
        if (alias.includes("/")) alias = alias.substring(0, alias.indexOf("/"));
        const aliasOk = await this._brandService.setRestrictedSiteIdFromAlias(alias);

        // If the alias isn't ok we should just return and then rely on brand-site-alias.component.ts to redirect us to the error page
        if (!aliasOk) return;
      }
    }

    if (this._shouldGetBookableAppts()) {
      await this._bookableApptsService.getBookableAppts();
    }
  }

  private _shouldGetBookableAppts(): boolean {
    if (this._jwtService.isPatient()) {
      return !this._patientService?.patientInfo?.prevent_appointment_booking;
    }

    // If we're not going to support new patients at this brand and a patient isn't logged in yet, there's no point in checking bookable appointments
    const existingOnly = this._brandService.brand.existing_patients_only_on_brand_url && !this._brandService.restrictedSite;
    return !existingOnly;
  }

  private _shouldHideAppLoadingSpinner(): boolean {
    //don't hide the app loading spinner for the short code component as it immediately redirects so
    //the app loading spinner will appear again anyway
    if (window.location.href.includes("/r/")) return false;
    return true;
  }

  private async _handleGlobalDomains(): Promise<void> {
    const { pathname } = this._locationService;
    const regionFromCache = this._cacheService.getSession(REGION_STORAGE_KEY);
    const stageFromCache = this._cacheService.getSession(STAGE_STORAGE_KEY);
    const hostnameFromCache = this._cacheService.getSession(HOSTNAME_STORAGE_KEY);

    if (!this._locationService.isRootDomain) {
      return;
    }

    if (regionFromCache) window.REGION = regionFromCache;
    if (stageFromCache) window.STAGE = stageFromCache;
    if (hostnameFromCache) this._locationService.hostname = hostnameFromCache;

    const match = pathname.match(/^\/r\/(.+)/);

    if (match) {
      await this._handleGlobalDomainShortCode(pathname, match[1]);
    } else if (!stageFromCache || !hostnameFromCache) {
      throw new Error("Not a short code link");
    }
  }

  private async _handleGlobalDomainShortCode(pathname: string, shortCode: string): Promise<void> {
    if (shortCode !== this._cacheService.getSession(PREVIOUS_SHORT_CODE_STORAGE_KEY)) {
      // #region Clear any existing session data to prevent the wrong details appearing
      this._cacheService.deleteSession(REGION_STORAGE_KEY);
      this._cacheService.deleteSession(STAGE_STORAGE_KEY);
      this._cacheService.deleteSession(HOSTNAME_STORAGE_KEY);

      if (this._jwtService.isPatient()) {
        await this._sessionService.clear();
      }
      // #endregion
    }

    const data = (await lastValueFrom(this._http.get(`${environment.GLOBAL_URL}/v1/short-codes/${shortCode}`))) as {
      region: string;
      stage: string;
      uri: string;
    };

    this._locationService.hostname = data.uri;

    // Update stage and hostname used to determine which backend and domain to use for branding respectively
    window.REGION = data.region;
    window.STAGE = data.stage;

    this._cacheService.setSession(REGION_STORAGE_KEY, data.region);
    this._cacheService.setSession(STAGE_STORAGE_KEY, data.stage);
    this._cacheService.setSession(HOSTNAME_STORAGE_KEY, this._locationService.hostname);
    this._cacheService.setSession(PREVIOUS_SHORT_CODE_STORAGE_KEY, shortCode);
  }

  private _redirectToLinkExpired(): void {
    this._navigationService.navigate("/restricted/expired");
  }

  private _determineDomainStage(): Promise<void> {
    if (this._locationService.isRootDomain) {
      // No point looking up the stage for the root domain. This will be handled by the short code
      return Promise.resolve();
    }

    return this._determineStage(`domain=${this._locationService.actualHostname}`);
  }

  private _determinePracticeStage(practiceId: string): Promise<void> {
    return this._determineStage(`practice_id=${practiceId}`);
  }

  private async _determineStage(queryStringParameters: string): Promise<void> {
    try {
      const { GLOBAL_URL } = environment;
      const response = (await lastValueFrom(this._http.get(`${GLOBAL_URL}/v1/stages?${queryStringParameters}`))) as { stage: string };
      const { stage } = response;

      if (window.STAGE !== stage) {
        Bugsnag.notify(`Domain stage changed from ${window.STAGE} to ${stage} for ${queryStringParameters}`);
      }

      window.STAGE = stage;
    } catch {
      Bugsnag.notify(new Error(`Failed to determine domain stage for ${queryStringParameters}`));
      // Let the downstream logic handle it and go to the error page
    }
  }

  private _handleCookies(cookieString: string): void {
    if (!cookieString) {
      return;
    }

    const cookies = cookieString
      .split(";")
      .map((cookie) => cookie.trim())
      .reduce((acc, cookie) => {
        const [key, value] = cookie.split("=");

        acc[key] = value;

        return acc;
      }, {} as Record<string, string>);

    if (cookies.rum) {
      [window.RUM_APP_MONITOR_ID, window.RUM_IDENTITY_POOL_ID] = cookies.rum.split(",");
    }

    if (!cookies.region || !cookies.stage) {
      return;
    }

    window.STAGE = cookies.stage;
    window.REGION = cookies.region;
  }
}

@Injectable()
export class AppInitPipService extends AppInitService {
  protected async _doInit() {
    // Automatically hide the app loading spinner if it's not hidden automatically
    utils.checkAppLoadingSpinnerIsRemoved(10);

    /*
      Check if we have previous PiP data (stage, site ID etc) and restore it
      If we had data which was restored, we need to init for paired because this device has already been paired
    */
    if (await this._portalInPracticeService.restorePipData()) {
      await this._portalInPracticeService.initForPaired();
    } else if (!this._jwtService.isPip()) {
      // If we've not already paired, we need to init for pairing to allow the practice user to pair the device
      await this._portalInPracticeService.initForPairing();
    }

    setupBugsnag(this._portalInPracticeService.deviceId);

    const deviceId = this._jwtService.getJWT("device_id");
    const practiceId = this._jwtService.getJWT("practice_id");

    Bugsnag.addMetadata("Device", {
      device_id: deviceId,
      practice_id: practiceId,
      site_id: this._portalInPracticeService.siteId,
      domain: this._locationService.hostname,
    });
    this._newRelicService.setUserId(deviceId);
  }
}

export function appInitServiceFactory(isPip: boolean): ClassProvider {
  return {
    provide: AppInitService,
    useClass: isPip ? AppInitPipService : AppInitService,
    multi: false,
  };
}
